.w100 {
  width: 100% !important;
}
.w95 {
  width: 95% !important;
}
.w90 {
  width: 90% !important;
}
.w80 {
  width: 80% !important;
}
.w75 {
  width: 75% !important;
}
.w70 {
  width: 70% !important;
}
.w60 {
  width: 60% !important;
}
.w55 {
  width: 55% !important;
}
.w50 {
  width: 50% !important;
}
.w45 {
  width: 45% !important;
}
.w40 {
  width: 40% !important;
}
.w35 {
  width: 35% !important;
}
.w30 {
  width: 30% !important;
}
.w25 {
  width: 25% !important;
}
.w22 {
  width: 22% !important;
}
.w20 {
  width: 20% !important;
}
.w15 {
  width: 15% !important;
}
.w13 {
  width: 13% !important;
}
.w10 {
  width: 10% !important;
}
.w5 {
  width: 5% !important;
}
