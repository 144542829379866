.create-shipment-page {
  .create-shipment-header {
    padding: 20px 0;
  }
  .page-title {
    padding-right: 10px;
    border-right: 0.5px solid rgba(0, 0, 0, 0.1);
  }
  .create-shipment-search {
    input {
      border: 1px solid $inputBorder;
    }
  }
  .input-wrapper {
    width: 250px;
  }
  .shipment-details-section {
    .subsection-content {
      padding: 20px 0;
    }
  }
  .add-edit-input,
  .phone-input > input {
    height: $inputLineHeight;
  }
  .ant-select-selection__rendered {
    line-height: 36px;
  }
}

.shipment-details-section {
  .add-edit-input,
  .phone-input > input {
    height: $inputLineHeight;
  }
  .ant-select-selection__rendered {
    line-height: 36px;
  }
}
