.btn {
  display: inline-block;
  outline: none;
  padding: 8px 48px;
  border-radius: 100px;
  cursor: pointer;
  border: 2px solid transparent;
  &.primary {
    background-color: $primary;
    color: $white;
    border: 2px solid $primary;
  }
  &.light {
    background-color: $sideMenuBg;
    color: $white;
    border: none;
  }
  &.secondary {
    background-color: $secondary;
    color: $white;
    border: 2px solid $secondary;
  }
  &.transparent {
    padding: 0;
    box-shadow: none;
    border: none;
    border-radius: 0;
    background-color: transparent;
    color: $primary;
  }
  &.white {
    background-color: $white;
    color: $primary;
    border: 2px solid $primary;
  }
  &.add {
    padding: 4px 24px;
  }
  &.small {
    padding: 3px 8px;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
  }
  &.btn-gray {
    background-color: $viewPageLabelColor;
    color: $white;
  }
  &.discard {
    color: $redColor;
    text-decoration: underline;
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.pointer {
  cursor: pointer;
}

.search-wrapper {
  position: relative;
  .search-icon {
    width: 18px;
    position: absolute;
    left: 10px;
  }
  input {
    background-color: $white;
    border: none;
    border-radius: 4px;
    height: 32px;
    padding-left: 36px;
    &::placeholder {
      color: $placeholder;
    }
  }
}

.center-content {
  margin: 0 auto;
}

.table-title-row,
.form-section-title-row {
  padding: 4px 8px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  background-color: $headerBgLight;
  .form-section-title {
    color: $primary;
    font-family: 'MilliardMedium';
  }
  .dropdown-icon {
    margin-top: -3px;
    transition: transform 0.2s ease-in-out;
    &.open {
      transform: rotate(180deg);
    }
  }
}

.subsection-header {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);
  padding: 10px 0;
  margin-top: 20px;
  p {
    color: $textGray;
  }
}

.no-wrap {
  white-space: nowrap;
}

.input-horizontal {
  .ant-select,
  .ant-input {
    width: 200px;
  }
}

.border-bottom-search {
  width: 250px;
  input {
    border-bottom: 1px solid $inputBorder;
    border-radius: 0;
  }
}

.search-input {
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  border: 1px solid #d9d9d9 !important;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.table-footer {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.bt1solid {
  border-top: 1px solid #d9d9d9;
}

.legend {
  &-circle {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    display: inline-block;
    border-radius: 50%;
  }
  &-line {
    height: 5px;
    width: 20px;
    margin-right: 5px;
    display: inline-block;
  }
  &-blue {
    background-color: $sideMenuBg;
  }
  &-green {
    background-color: $green;
  }
  &-red {
    background-color: $legendRed;
  }
  &-orange {
    background-color: $secondary;
  }
  &-lightorange {
    background-color: $legendOrange;
  }
  &-gray {
    background-color: $viewPageLabelColor;
  }
  &-lightgray {
    background-color: $borderGray;
  }
}

.bc {
  &-blue {
    background-color: $sideMenuBg !important;
  }
}

input {
  &.underline-input {
    border: none;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.23);
    border-radius: 0;
    box-shadow: none !important;
    &::placeholder {
      color: rgba(0, 0, 0, 0.24);
    }
  }
}

.light-heading {
  margin-bottom: 20px;
  &__header {
    font-family: 'MilliardRegular';
    padding-bottom: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: rgba(154, 154, 154, 0.49);
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);
  }
}
