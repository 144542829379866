/**
 * Documents table styling
 */
.documents-table {
  th {
    .ant-table-column-title {
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .document {
    &-table-header {
      color: rgba(0, 0, 0, 0.4);
    }

    &-download-link {
      color: #008bea;
      text-decoration: underline;
    }
  }
}
