/**
 * Reports page stylesheet
 */

 .reports-page .range-picker {
  position: absolute;
  top: 0;
  right: 20px;
}

.reports-page {
  .totals {
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    padding: 20px;

    &__items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;

      .chart-wrapper {
        max-width: 150px;
      }
    }
  }

  .card {
    min-width: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    .count {
      font-weight: bold;
      font-size: 24px;
      color: black;
    }

    &-title {
      font-weight: 500;
    }

    &--rounded {
      border-radius: 20px;
    }
  }
}