// Layout styles
.ant-layout {
  background-color: $white;
}

.ant-layout-content {
  position: relative;
}

.ant-divider-horizontal {
  margin-top: 0;
  margin-bottom: 0;
}

.overview-layout {
  background-color: transparent !important;
}
.ant-select-enabled {
  overflow: hidden;
}
// End Layout styles

// Tabs styles
.user-media-tabs {
  width: 100%;

  .ant-tabs-nav {
    width: 100%;

    .ant-tabs-ink-bar {
      height: 2px;
    }
  }

  .ant-tabs-nav > div:first-child {
    display: flex;

    .ant-tabs-tab {
      margin: 0;
      width: 20%;
      text-align: center;
      padding: 12px;

      &.ant-tabs-tab-active {
        color: $primary;
      }
    }
  }
}

.ant-tabs-bar {
  margin: 0;
  border-bottom: none;
}

.ant-tabs-nav .ant-tabs-tab {
  padding: 8px 16px 24px;
  @media screen and (max-width: $small) {
    font-size: 10px;
  }
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: $primary;
  font-weight: 600;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: $primary;
}

.ant-tabs-ink-bar {
  height: 3px;
  background-color: $primary;
}

// End Tabs styles

// Table styles
.ant-table-fixed {
  @media screen and (max-width: $small) {
    font-size: 10px;
  }
}

th .ant-checkbox-inner {
  display: none;
}

td a {
  color: $text;

  &:hover {
    color: $text;
  }
}

.ant-checkbox-inner {
  border-color: $checkboxBorderColor;

  &:focus {
    border-color: $checkboxBorderColor;
  }
}

.ant-checkbox-checked::after {
  border: 1px solid $text;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary;
  border-color: $primary;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $text;
}

.ant-table-tbody > tr:hover,
.ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background-color: $textLight;
}

.assetsTableRowHeight {
  height: 80px;
}

.ant-table-tbody > tr > td.ant-table-selection-column {
  padding: 4px;
}

.ant-table-row-expanded::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-49%, -47%);
}

.highlighted-row {
  background-color: $highlightedRow;
}

// End Table styles

// Pagination styles
.ant-pagination {
  @media screen and (max-width: $small) {
    font-size: 10px;
  }
}

.ant-pagination-item-active {
  border-color: $text;

  &:hover {
    border-color: $text;
  }

  &:hover a {
    color: $text;
  }
}

.ant-pagination-item-active {
  border-color: $text;

  &:hover {
    border-color: $text;
  }
}

.ant-pagination-item-active {
  border-color: $text;

  &:hover,
  &:focus {
    border-color: $text;
  }
}

.ant-pagination-item-active a {
  color: $text;

  &:hover {
    border-color: $text;
    color: $text;
  }
}

.ant-pagination-item-active:focus a {
  color: $text;
}

.ant-pagination-item:hover {
  border-color: $text;
}

.ant-pagination-item:hover a {
  color: $text;
}

.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:focus .ant-pagination-item-link {
  color: $text;
  border-color: $text;
}

// End Pagination styles

// Search box styles
.ant-input {
  @media screen and (max-width: $small) {
    font-size: 12px !important;
  }
}

.ant-input-number {
  width: unset;
}

.ant-input-search {
  height: 48px;
}

.ant-input-affix-wrapper {
  width: 300px !important;

  &.radius-input {
    max-width: 100%;
    width: 100% !important;

    input {
      padding: 10px 48px 10px 10px !important;
    }

    .ant-input-suffix {
      left: unset;
      right: 12px;
    }
  }
}

.ant-input-affix-wrapper .ant-input-suffix {
  left: 12px;
  right: unset;
}

.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-left: 48px;
  padding-right: 0;
  background-color: $searchInputBgColor;
  border: none;
  border-radius: 0;
}

.ant-input {
  font-size: 14px;
  height: 100%;
}

// End Search box styles

// Modal styles
.ant-modal {
  // width: 400px !important;
}

.ant-modal-content {
  overflow: hidden;
  position: relative;
}

.ant-modal-body .ant-table {
  padding: 0;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    padding: 12px 16px;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    @media screen and (max-width: $small) {
      font-size: 12px;
    }

    &:hover {
      background-color: $backgroundGray;
    }
  }
}

// End Modal styles

// Breadcrumbs styles
.ant-breadcrumb-link,
.ant-breadcrumb-separator {
  font-size: 20px;
}

.ant-breadcrumb a:hover {
  color: $text;
}

// End Breadcrumbs styles

// Select styles
.option-non-selectable {
  background-color: $white !important;

  &:hover {
    cursor: default !important;
    background-color: $white !important;
  }
}

.ant-select-selection__placeholder {
  display: block !important;
}

.double-row-select-list-item {
  .ant-select-selection__rendered {
    line-height: unset !important;
  }

  .ant-select-selection__placeholder {
    height: unset !important;
    line-height: 39px !important;
  }
}

.ant-select-selection-selected-value {
  max-width: unset;
}

.ant-select-dropdown-placement-bottomLeft {
  width: auto !important;
}

.ant-input-group {
  height: 100%;
}

.ant-select-selection--single {
  height: 100%;
}

.ant-select-selection__rendered {
  line-height: $inputLineHeight;
}

// End Select styles

// Progress styles
.ant-progress {
  height: 16px;
}

.ant-progress-inner {
  border-radius: 4px;
}

.ant-progress-bg {
  border-radius: 0;
}

// End Progress styles

// Tags styles
.ant-tag {
  padding: 5px 10px;
  border-radius: 20px;
  border: none;
  background-color: $borderGray;
}

// End Tags Styles

// Tooltip styles
.ant-tooltip-inner {
  background-color: $white;
  color: $title;
}

.ant-tooltip-arrow::before {
  background-color: $white;
}

// End tooltip styles

// Toast styles
.ant-notification-notice-message {
  @media screen and (max-width: $small) {
    font-size: 12px !important;
  }
}

// Form styles
.ant-form-item {
  margin-bottom: 0 !important;
}

// Rangepicker styles
.ant-calendar-picker-input {
  display: flex;
  align-items: center;
}

.ant-modal-wrap {
  z-index: 1000;
}

.ant-calendar-picker-input {
  height: $inputLineHeight;

  .ant-calendar-range-picker-separator {
    height: unset;
  }
}

.ant-select-selection {
  height: $inputLineHeight;
}

.assets-list {
  .ant-select-selection {
    height: auto !important;
  }
}

.ant-select-dropdown {
  display: block !important;
}

.ant-select-dropdown-hidden {
  display: none !important;
}
