.change-password-modal-content {
  border-radius: 15px;
  .change-password-input {
    padding: 18px 24px;
    border-radius: 4px;
    background-color: $white;
    font-size: 16px;
    margin-bottom: 24px;
    &::-webkit-input-placeholder {
      font-style: italic;
    }
  }
  .show-password-sign {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 24px;
    cursor: pointer;
  }
}
