// content wrapper
$wrapperWidth: 88%;
$maxWrapperWidth: 1636px;
$maxWrapperWidthWithoutPadding: 1440px;
$widerThanMaxWrapperWidth: 1637px;
$mobileWrapperWidth: 90%;
$aboveMaxWidthPadding: calc((100vw - #{$maxWrapperWidthWithoutPadding}) / 2);

// header
$headerHeight: 88px;

// side menu
$sideMenuWidth: 98px;

// input fields
$inputLineHeight: 38px;
