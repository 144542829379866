.add-edit-form-component {
  .form-page-section {
    .input-wrapper,
    .checkbox-wrapper {
      margin-left: 50px;
      @media screen and (max-width: $retina) {
        margin-left: 20px;
      }
    }
    .input-wrapper {
      @media screen and (max-width: $retina) {
        width: 190px;
      }
    }
    .ant-input {
      font-size: 14px;
    }
    .calculator-label {
      font-family: 'MilliardBold';
      margin-bottom: 10px;
    }
    &.view-driver-section {
      .rdrDefinedRangesWrapper {
        display: none;
      }
      .rdrDay:hover {
        cursor: default;
      }
      .agenda-item {
        display: flex;
      }
      .box {
        height: 1rem;
        width: 20px;
        margin-right: 5px;
        margin-bottom: 12px;
        border-radius: 4px;
      }
    }
  }
  .inspections-table,
  .registrations-table {
    width: 100%;
  }
}
