@font-face {
  font-family: 'MilliardRegular';
  src: url('../assets/fonts/Milliard Book.woff') format('woff');
}
@font-face {
  font-family: 'MilliardBold';
  src: url('../assets/fonts/Milliard Bold.woff') format('woff');
}
@font-face {
  font-family: 'MilliardMedium';
  src: url('../assets/fonts/Milliard Medium.woff') format('woff');
}
