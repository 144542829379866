/**
 * Shipments list sidebar
 */
.track-shipments-list {
  position: absolute;
  bottom: 0;
  max-width: 1300px;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 999;
  height: calc(100% - 85px);
  padding: 18px 28px;
  .ant-table-expanded-row {
    > td {
      padding: 0;
    }
  }
}
