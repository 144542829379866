.side-menu-wrapper {
  width: $sideMenuWidth;
  position: fixed;
  height: 100vh;
  background-color: $sideMenuBg;
  z-index: 100;
  .side-menu {
    background-color: transparent;
    width: 100%;
    height: 100%;
    border-right: none;
    padding-top: 10px;
  }
  .menu-item-wrapper {
    padding: 5px 0;
    position: relative;
    @media (max-width: $retina) {
      padding: 0;
    }
  }
  .ant-menu-item {
    display: flex;
    align-items: center;
    padding-right: 0;
    width: 100%;
    height: unset;
    margin-top: unset;
    margin-bottom: unset;
    padding: unset;
    overflow: hidden;
    font-size: 14px;
    line-height: unset;
    text-overflow: ellipsis;
    &::after {
      border-right: none;
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: unset;
  }
  .sidemenu-link,
  .logout-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .menu-icon-wrapper {
    padding: 13px;
    border-radius: 50%;
    &.active {
      background-color: $sideMenuItemActive;
    }
    img {
      width: 26px;
    }
  }
  .logout-link {
    position: absolute;
    bottom: 20px;
  }
}
