/**
 * Dispatch Map page styling
 */

.not-expandable .ant-table-row-expand-icon-cell {
  visibility: hidden;
}
.ant-table-row-expand-icon {
  position: relative;
  &.ant-table-row-expanded {
    &::after {
      transform: translate(-50%, -50%);
    }
  }
  &::after {
    font-size: 17px;
    line-height: 17px;
  }

}

/** Common - START **/
.filter-trigger-item {
  background: rgba($white, 0.9);
  height: 43px;
}

.leaflet {
  &-tile-pane {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  &-popup-content-wrapper {
    border-radius: 0;
  }
}

/** Common - END **/

.dispatch-map {
  &-header {
    @include dispatch_position();
    top: 88px;

    .trip-board-wrapper,
    .drivers-wrapper {
      min-width: 250px;
      justify-content: space-between;
    }

    .planner-wrapper {
      min-width: 350px;
      justify-content: space-between;
    }
  }

  &-label {
    color: $title;
    font-size: 16px;
    line-height: 20px;
    font-family: 'MilliardBold';
    margin-bottom: 25px;
  }

  &-footer {
    @include dispatch_position();
    bottom: 0;

    img {
      display: block;
      margin: auto;
    }

    &-filters {
      width: 58px;
      display: flex;
    }

    &__radius-filter {
      min-width: 270px;
      position: relative;
      top: 0;

      &.radius-opened {
        top: -136px;
      }

      &-search {
        background-color: $white;

        .input-label {
          font-style: italic;
        }
      }
    }
  }

  &-filters-wrapper {
    position: absolute;
    bottom: 45px;
    min-width: 320px;
    background-color: $white;
    z-index: 999;
    padding: 18px 28px;

    &-options {
      display: flex;
      flex-direction: column;

      label {
        margin-left: 0 !important;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ant-checkbox {
          order: 1;
        }

        span:nth-child(2) {
          padding-left: 0;
        }

        p {
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &-legend {
      .legend-item {
        margin-bottom: 10px;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  &-assigning-wrapper {
    @include dispatch_sidebar_position();
  }

  &-drivers-wrapper {
    @include dispatch_sidebar_position();
  }
  &-organize-tour {
    @include dispatch_position();
    top: 88px;
    right: 0;
    max-width: 360px;
    width: 100%;
    height: calc(100% - 88px);
    overflow-y: scroll;

    &__trigger {
      justify-content: space-between;
    }

    &__details {
      background-color: $white;
      display: flex;
      flex-direction: column;

      .headline {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
      }

      .sub-headline {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: $black;
        max-width: 360px;
        width: 100%;
      }

      .has-bl {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: calc(100% - 40px);
          bottom: 0;
          left: 40px;
          border-bottom: 0.5px solid rgba(0, 0, 0, 0.23);
        }
      }

      .details {
        &-top,
        &-bottom {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 10px;
            width: 15px;
            height: 15px;
            border-radius: 50%;
          }
        }

        &-top {
          &::before {
            background-color: $white;
            border: 2px solid $secondary;
          }

          &::after {
            content: '';
            height: 100%;
            width: 15px;
            background-image: url('../../assets/images/dispatch/blue-dot.svg');
            background-position: center center;
            background-repeat: repeat-y;
            background-size: 5px 10px;
            left: 10px;
            top: 23px;
            position: absolute;
            bottom: 0;
          }
        }

        &-bottom {
          &::before {
            background-color: $white;
            border: 2px solid $green;
          }
        }
      }
      &.tracking-shipment {
        .details {
          &-top {
            &::before {
              background-color: $white;
              border: 2px solid $sideMenuBg;
            }
            &:not(:first-child) {
              //margin-top: 10px;
            }
            &:not(:last-child) {
              .sub-headline {
                border-bottom: 0.5px solid rgba(0, 0, 0, 0.23);
                padding-bottom: 10px;
              }
            }
            &:last-child {
              &::after {
                content: none;
              }
            }
            &::after {
              top: 14px;
            }
          }
        }
      }
    }

    &__main {
      &.vehicle-route-actions {
        .dispatch-map-organize-tour-list__item:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.51);
        }
      }

      .holder {
        background-color: $sideMenuBg;
        height: 43px;

        p {
          color: $white;
        }

        &-vehicle {
          height: 53px;

          p {
            color: $white;
          }
        }
      }

      .inside-details {
        background-color: $white;

        &__add-trip {
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
          padding-top: 6px;
          padding-bottom: 6px;
        }

        &__add-trip-event,
        &__add-trip {

          p {
            color: $sideMenuBg;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
          }
        }

        &__single-trip {
          p,
          .input-label {
            font-style: normal;
            font-size: 13px;
            line-height: 15px;
            color: $viewPageLabelColor;
            margin-bottom: 10px;
          }

          p {
            margin-bottom: 0;
          }
        }
      }
    }

    &-list {
      &__item {
        background-color: $white;

        .trip {
          &-tour {
            background-color: #fffbf4;

            .dot {
              margin: 16px 0;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: $sideMenuBg;
              display: block;
            }

            &-status {
              width: 20px;
              height: 20px;
              //background-color: #5AA79E;
              border: 1px solid rgba(24, 20, 21, 0.49);
              border-radius: 50%;
            }
          }

          &-time {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 23px;
          }

          &-driver {
            background-color: $white;

            &-name {
              font-size: 16px;
              line-height: 18px;
            }

            &-details {
              &-name {
                font-size: 18px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.assign-trailer-modal {
  .modal-trailer__data,
  .ant-table-wrapper {
    width: 100%;
  }

  /** Radius **/
  &__radius-filter {
    width: 300px;
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    left: 5px;
    bottom: 5px;
    z-index: 999;
    padding: 20px;
  }

  &__available-trailers {
    thead {
      tr {
        vertical-align: top;
      }

      th {
        .ant-table-header-column {
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.03em;
          color: #7b7b7b;
          font-family: 'MilliardMedium';
        }

        p {
          .ant-table-column-title {
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
    }
  }
}

//Search input field for driver/trailer/truck modals
.trailer-search-input {
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 4px;
  max-width: 100%;
  width: 100%;
  padding: 5px 10px;

  &:focus {
    outline: 0;
  }

  &.ant-input-affix-wrapper .ant-input {
    min-height: $inputLineHeight;
  }
}

.track-shipments-list {
  .trailer-search-input {
    background: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
  }
}

// Planner list
.planner-list {
  &__inner {
    position: absolute;
    z-index: 200;
    background-color: $white;
    height: calc(100vh - 88px);
    top: 88px;
    max-width: 1700px;
    width: 100%;
    padding: 18px 28px;

    .ant-table-thead {
      th {
        background-color: transparent;
        color: $borderGray;
        font-size: 13px;
        font-weight: bold;
        padding: 10px;
      }
    }

    .ant-table-tbody {
      tr {
        font-size: 13px;

        td {
          &:nth-child(5),
          &:first-of-type {
            background-color: #fff9f3;
          }
        }
      }

      > tr > td {
        border: none;
      }
    }
  }

  &__popup {
    animation-name: fadeIn;
    animation-duration: 0.4s;
    background-clip: padding-box;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    left: 0;
    list-style-type: none;
    margin: 0;
    outline: none;
    padding: 0;
    position: absolute;
    text-align: left;
    top: 0;
    overflow: hidden;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    li {
      clear: both;
      color: rgba(0, 0, 0, 0.65);
      cursor: pointer;
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      margin: 0;
      padding: 5px 12px;
      transition: all 0.3s;
      white-space: nowrap;
      -webkit-transition: all 0.3s;

      &:hover {
        background-color: #e6f7ff;
      }

      & > i {
        margin-right: 8px;
      }
    }
  }
}

/** Draggable locations list **/
.locations-list {
  padding-left: 20px;
  position: relative;

  &__item {
    position: relative;
    border: 1px solid $inputBorder;
    border-radius: 4px;
    padding: 8px 5px;

    &::before {
      content: '';
      position: absolute;
      left: -20px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background-color: $white;
      z-index: 10;
      border: 2px solid $sideMenuItemActive;
      top: 49%;
      transform: translateY(-50%);
    }


    &:first-child {
      &::after {
        content: none;
      }
    }

    &:not(:last-child) {
      margin-bottom: 9px;
    }
  }

  &::after {
    top: 21px;
    left: 0;
    position: absolute;
    content: '';
    height: calc(100% - 45px);
    width: 15px;
    background-image: url('../../assets/images/dispatch/blue-dot.svg');
    background-position: center center;
    background-repeat: repeat-y;
    background-size: 5px 12px;
  }

  &.hide-dots {
    &::after {
      content: none;
    }
  }

}

.route-action-items-wrapper {

  position: absolute;
  z-index: 10;
  top: calc(#{$headerHeight} + 43px + 10px);
  left: $sideMenuWidth;
  max-width: 350px;
  width: 100%;
  height: calc(100% - #{$headerHeight} - 96px - 10px);
  overflow-x: hidden;
  &.has-over {
    overflow-y: scroll;
  }
  .inside-details {
    background-color: $white;
  }
}


@keyframes fadeIn {
  0% {
    transform: translateY(-25%);
  }
  50% {
    transform: translateY(4%);
  }
  65% {
    transform: translateY(-2%);
  }
  80% {
    transform: translateY(2%);
  }
  95% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(0%);
  }
}