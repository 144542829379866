.employees-page {
  .header-row {
    width: calc(100% - 24px);
    padding: 4px 0;
    margin-left: 24px;
    .column {
      padding: 0 16px;
      font-family: 'MilliardRegular';
      font-size: 12px;
    }
  }
  .select-employees-row {
    position: relative;
    .select-employees-dropdown {
      background-color: $white;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
      position: absolute;
      bottom: 30px;
      left: 0;
      padding: 4px 8px;
      .ant-checkbox-group {
        .ant-checkbox-wrapper {
          padding: 10px 0;
          margin-left: 0;
        }
      }
    }
  }
  .employees-table {
    tbody > tr:hover {
      cursor: pointer;
    }
  }
  .employee-photo {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 200px;
    width: 200px;
    border-radius: 200px;
  }
}
