/**
 * Dispatch map container styling
 */
.dispatch-map-marker {
  &__type {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;

    &.pickup {
      color: $secondary;
    }

    &.delivery {
      color: $green;
    }
  }

  &__company {
    color: $sideMenuBg;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 8px;
  }

  &__info {
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
  }

  &__company {
    margin-bottom: 8px;
    display: inline-block;
  }
}
