.tracking-shipment {
  &__map-wrapper {
    width: 350px;
    position: relative;
  }
  &__top-wrapper {
    height: 43px;
    background-color: $sideMenuBg;
    display: flex;
    align-items: center;
    color: $white;
  }
  &__bottom-wrapper {
    position: absolute;
    top: 43px;
    width: 350px;
  }
  &__current-info-top {
    padding: 10px;
    background-color: #fffbf4;
  }
  &__current-info-bottom {
    background-color: $white;
    padding: 10px;
  }
}