.text-bold {
  font-family: 'MilliardBold' !important;
}

.text-regular {
  font-family: 'MilliardRegular' !important;
}

.text-medium {
  font-family: 'MilliardMedium' !important;
}

.title {
  font-size: 32px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}

.fs75 {
  font-size: 0.75rem;
}

.fs150 {
  font-size: 1.5rem;
}

.text-black {
  color: $black;
}
.text-blue {
  color: $sideMenuBg;
}
.text-orange {
  color: $secondary;
}
.text-green {
  color: $green;
}
.text-dkred {
  color: $redColor;
}
