.PhoneInputCountryIconImg {
  width: 70% !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.PhoneInputInput {
  height: 32px;
  border: 1px solid $inputBorder;
  border-radius: 4px;
  padding: 4px 11px;
  font-size: 12px;
}
.PhoneInputInput::placeholder {
  color: #b3b3b3;
}
.PhoneInputInput:focus {
  border-color: $borderGray;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
