.add-edit-form-component,
.modal {
  .form-page-section {
    .section-content {
      padding: 16px 14px 48px;
    }
    .input-wrapper {
      width: 250px;
      .ant-upload,
      .ant-calendar-picker,
      .ant-input-number {
        width: 100%;
      }
    }
    .associations-section {
      .input-wrapper {
        width: 400px;
      }
    }
    &.ant-upload-icons-removed {
      .ant-upload-list-item-card-actions {
        display: none;
      }
    }
    .ant-input-group-addon {
      padding: 0 6px;
    }
    .select-odometer-units,
    .select-weight-units {
      margin: 0;
      overflow: inherit;
    }
    .view-page-item-wrapper {
      width: 250px;
      .label {
        color: $viewPageLabelColor;
      }
      .content {
        color: $title;
      }
    }
  }
  //.status-dot {
  //  width: 6px;
  //  height: 6px;
  //  display: flex;
  //  border-radius: 50%;
  //  background-color: $green;
  //  &.unavailable {
  //    background-color: $error;
  //  }
  //}
}

.status-dot {
  width: 6px;
  height: 6px;
  display: flex;
  border-radius: 50%;
  background-color: $green;
  &.unavailable {
    background-color: $error;
  }
}

.modal {
  .ant-upload,
  .ant-calendar-picker,
  .ant-input-number {
    width: 100%;
  }
  .select-odometer-units,
  .select-weight-units {
    margin: 0;
    overflow: inherit;
  }
}

.customer-modal-content {
  .input-wrapper {
    width: 45%;
  }
  .has-select-disabled {
    .css-g1d714-ValueContainer {
      max-height: $inputLineHeight;
    }
  }
}

.location-modal-content {
  .input-wrapper {
    width: 35%;
    .add-edit-input {
      height: 39px;
    }
  }
  .map-wrapper {
    height: 300px;
    .view-fleet-map {
      height: 250px;
      z-index: 10;
    }
  }
  .geocoder-control.geocoder-control-expanded {
    width: 250px !important;
  }
}

.form-page-title-wrapper {
  padding: 16px 14px;
  .form-page-title {
    font-size: 16px;
    font-family: 'MilliardMedium';
    color: $black;
    text-transform: capitalize;
    margin-left: 10px;
  } 
}
