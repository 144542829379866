$primary: #1873b8;
$text: rgba(0, 0, 0, 0.51);
$textGray: rgba(154, 154, 154, 0.49);
$textLight: #f2f2f2;
$secondary: #f59233;
$backgroundGray: #dedede;
$borderGray: #96afcd;
$inputBorder: #d9d9d9;
$checkboxBorderColor: #dadada;
$searchInputBgColor: #f2f2f2;
$white: #ffffff;
$black: #000000;
$menuItemsColor: rgba(0, 0, 0, 0.65);
$title: #181415;
$error: #ee0202;
$highlightedRow: rgba(11, 182, 28, 0.15);
$headerBgLight: #f0f7fd;
$headerBgDark: #000000;
$sideMenuBg: #008bea;
$sideMenuItemActive: #006fc7;
$placeholder: rgba(123, 123, 123, 0.2);
$chartTextColor: #444444;
$green: #33c141;
$headerShowActiveText: #68ba70;
$viewPageLabelColor: #7b7b7b;
$vacation: blue;
$sickleave: red;
$freeday: purple;
$holiday: pink;
$otherleave: orange;
$legendRed: #ed4c5c;
$legendOrange: #ffd3a8;
$redColor: #d93c3c;
$lightGray: rgba(0, 0, 0, 0.31);

.color-light-gray {
  color: $lightGray;
}
.color-border-gray {
  color: $borderGray;
}

.color-title {
  color: $title;
}
.color-primary {
  color: $primary !important;
}
.color-secondary {
  color: $secondary;
}
.color-white {
  color: $white;
}
.color-error {
  color: $error !important;
}
.color-text-gray {
  color: $textGray;
}
.color-header-show-active-text {
  color: $headerShowActiveText;
}

.bg-primary {
  background-color: $primary !important;
}
.bg-header-bg-light {
  background-color: $headerBgLight;
}
.bg-header-bg-dark {
  background-color: $headerBgDark;
}
.bg-vacation {
  background-color: $vacation;
}
.bg-sickleave {
  background-color: $sickleave;
}
.bg-freeday {
  background-color: $freeday;
}
.bg-holiday {
  background-color: $holiday;
}
.bg-otherleave {
  background-color: $otherleave;
}
