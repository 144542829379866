.brokerage-page {
  .header-button {
    position: absolute;
    top: 0;
    right: 20px;
  }
  .shipments-component {
    .diagram-section-content {
      display: flex;
      .shipment-status-box {
        border: 0.5px solid rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        padding: 20px;
        .charts-wrapper {
          margin-top: 20px;
        }
      }
      .completed-shipments-box {
        border: 0.5px solid rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        padding: 20px;
        .ant-input {
          font-size: 12px;
          width: 230px;
        }
        .bar-chart-wrapper {
          height: 242px;
          display: flex;
          padding-bottom: 10px;
        }
      }
    }
  }
}
