/**
 * Mixins stylesheet file
 */

@mixin dispatch_position() {
  position: absolute;
  z-index: 10;
}

@mixin dispatch_sidebar_position() {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: flex-end;
}
