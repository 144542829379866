@import '~antd/dist/antd.css';
@import 'react-big-calendar/lib/sass/styles';
@import 'breakpoints';
@import 'colors';
@import 'fonts';
@import 'variables';
@import 'margins';
@import 'paddings';
@import 'widths';
@import 'heights';
@import 'flex-helpers';
@import 'ant-design-override';
@import 'global';
@import 'shared';
@import 'mixins/mixins';
@import 'login';
@import 'typography';
@import 'bottom-menu';
@import 'content-container';
@import 'side-menu';
@import 'header';
@import 'employees';
@import 'breadcrumbs';
@import 'success-modal';
@import 'loader';
@import 'fleet';
@import 'change-password-modal';
@import 'add-edit-form-component';
@import 'add-edit-fleet';
@import 'phone-number-input-override';
@import 'brokerage';
@import 'chart';
@import 'create-shipment';
@import 'async-select';
@import 'shipments';
@import 'documents-table';
@import 'carriers-modal-list';
@import 'dispatch/routing_machine_overrides';
@import 'dispatch/dispatch-map-page';
@import 'dispatch/dispatch-map-marker';
@import 'dispatch/shipments_list';
@import 'dispatch/driver-trailer-list';
@import 'dispatch/dispatch-data-table';
@import 'dispatch/tracking-shipment';
@import 'organization/organization';
@import "payroll";
@import "reports";
