.breadcrumbs {
  position: fixed;
  top: $headerHeight;
  left: $sideMenuWidth;
  padding: 2px 16px;
  background: $black;
  width: 100%;
  .back-link {
    display: flex;
    align-items: center;
    padding-right: 20px;
    img {
      margin-top: -3px;
    }
  }
  .border-right {
    width: 1px;
    height: 14px;
    margin-top: -2px;
    background-color: $white;
  }
  p,
  span {
    color: $white;
  }
  .breadcrumbs-arrow {
    margin: -2px 10px 0;
  }
}
