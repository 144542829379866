.login-layout {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $white;
  }

  p {
    color: $white;
  }

  .link,
  a {
    color: $white;
    &:hover {
      color: $white;
    }
  }
  width: 100vw;
  height: 100vh;
  background-color: $primary;
  position: relative;
  overflow: hidden;
  .background-image {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 0;
  }
  .login-layout-content {
    height: 100%;
    max-width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
  }
  .login-content,
  .reset-password-content {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
    .logo {
      width: 400px;
    }
    .login-inner-content {
      width: 400px;
      top: 50%;
    }
    .login-input {
      padding: 18px 24px;
      border-radius: 48px;
      background-color: $white;
      font-size: 16px;
      margin-bottom: 24px;
      &::-webkit-input-placeholder {
        font-style: italic;
      }
    }
    .show-password-sign {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 24px;
      cursor: pointer;
    }
    .forgot-password-link {
      text-decoration: underline;
    }
    .login-box {
      height: 24px;
    }
  }
}
