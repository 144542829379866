.fleet-page {
  .fleet-overview {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 10px;
  }
  .fleet-chart-component {
    width: 50%;
    .fleet-overview-chart-component {
      width: 23%;
      padding: 0 5px;
    }
  }
  .utilization-component {
    width: 50%;
    .utilization-component-content {
      padding: 0 5px;
    }
    .utilization-select {
      width: 200px;
      margin-left: 10px;
      text-transform: capitalize;
    }
    .ant-table-column-title {
      font-size: 12px;
    }
  }
  .leasing-calculation-component {
    padding: 0 5px;
    .leasing-calculation-select {
      width: 200px;
      margin-left: 10px;
      text-transform: capitalize;
    }
    .ant-table-column-title {
      font-size: 11px;
    }
  }
  .fleet-table {
    tbody > tr:hover {
      cursor: pointer;
    }
  }
  .map-wrapper {
    height: 350px;
    padding: 1rem 0;
    .view-fleet-map {
      height: 100%;
      z-index: 10;
    }
  }
}
