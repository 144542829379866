.bottom-menu {
  width: 90%;
  margin-top: 80px;
  z-index: 10;
  @media (max-width: $retina) {
    margin-top: 50px;
  }
  .bottom-menu-link {
    &.active {
      .bottom-menu-title {
        text-decoration: underline;
      }
    }
  }
}
