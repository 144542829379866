.content-container {
  position: absolute;
  width: 40%;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $white;
  padding: 96px 48px;
  transform: scaleX(0);
  transform-origin: 100% 50%;
  transition: transform 0.3s ease-in-out;
  &.open {
    transform: scaleX(1);
  }
  h2 {
    color: $title;
    font-size: 40px;
  }
  p {
    color: $text;
  }
  .close-icon-wrapper {
    cursor: pointer;
  }
}
