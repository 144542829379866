/**
 * Driver/Trailer list styling
 */

.driver-trailer-list {
  position: absolute;
  top: 88px;
  max-width: 1300px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 999;
  height: calc(100% - 88px);
  padding: 18px 28px;
}
