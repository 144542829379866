.h100vh {
  height: 100vh;
}

.h70vh {
  height: 70vh;
}

.h100 {
  height: 100%;
}
