* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'MilliardRegular', sans-serif;
  position: relative;

  &.no-scroll {
    height: 100%;
    overflow-y: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'MilliardMedium';
  color: $title;
}

p {
  font-family: 'MilliardRegular';
  margin-top: 0;
  margin-bottom: 0;
  color: $text;
}

.link,
a {
  display: inline;
  cursor: pointer;
  color: $text;

  &:hover {
    color: $text;
  }
}

button {
  outline: none;
}

input {
  font-family: 'MilliardRegular', sans-serif;
  min-height: $inputLineHeight;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.page {
  padding-left: $sideMenuWidth;
  padding-top: calc(#{$headerHeight} + 24px);
  min-height: 100vh;
  background-color: $black;
  &.create-shipment-page {
    background-color: transparent;
  }
}

.page-content {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: $white;
  min-height: calc(100vh - calc(#{$headerHeight} + 24px));
}

.page-title {
  font-family: 'MilliardMedium', sans-serif;
}

.section {
  padding: 64px 0;
}

.wrapper {
  width: 100%;
  padding: 0 6%;
  @media (min-width: $maxWrapperWidth) {
    padding: 0 $aboveMaxWidthPadding;
  }
  @media (max-width: $extrasmall) {
    padding: 0 5%;
  }
}

.go-to {
  color: $sideMenuBg !important;
  text-decoration: underline;
  cursor: pointer;
}

.discard {
  color: $redColor;
  text-decoration: underline;
  cursor: pointer;
}

.current-time {
  position: relative;
  top: -27px;

  &__timezone {
    background-color: #008bea;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: $white;
    font-size: 14px;
    line-height: 16px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.has-border-right {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.top_details {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
  padding-bottom: 10px;
  margin-bottom: 20px;

  .text-bold {
    color: $title;
    opacity: 0.2;
  }
}

.d-iblock {
  display: inline-block;
}
