.view-shipment-map {
  height: 200px;
  z-index: 10;
}

.probill-modal-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $title;
}
