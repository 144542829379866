/**
 * Payroll page stylesheet
 */

.payroll-page .header-button {
  position: absolute;
  top: 0;
  right: 20px;
}

.compensation-table-row {
  cursor: pointer;
}

.compensation-table-row-inactive {
  cursor: pointer;
  background-color: #f1eeee;
  color: #7b7b7b;
}

.compensation-table-expanded-row {
  background-color: #f1eeee;
  color: #7b7b7b;
}
