/**
 * Dispatch Data Table styling
 */
.dispatch-data-table {
  &-single {
    &__header {
      font-family: 'MilliardBold';
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      color: $borderGray;
    }
  }
}
