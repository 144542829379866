.charts-wrapper {
  .chart-component {
    width: 20%;
    margin: 0 auto;
  }
  .CircularProgressbar {
    height: 150px;
  }
  .CircularProgressbar-text {
    font-family: MilliardBold;
  }
  .chart-title {
    text-align: center;
    margin-top: 10px;
    font-size: 11px;
    height: 25px;
    // min-height: 32px;
  }
  .chart-text {
    text-align: center;
    font-size: 13px;
    margin-top: 10px;
    font-family: MilliardBold;
  }
  .chart-link {
    text-align: center;
    font-size: 13px;
    margin-top: 10px;
    height: 25px;
    font-family: MilliardBold;
    color: $sideMenuBg;
    text-decoration: underline;
  }
}

.bar-wrapper {
  padding: 0 2%;
  flex: 1;
  align-self: flex-end;
  .bar {
    background-color: $sideMenuBg;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: $white;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    align-self: flex-end;
    position: relative;
    padding: 5% 0;
    min-height: 80px;
    .bar-title {
      position: absolute;
      bottom: -25px;
      right: 0;
      left: 0;
      color: $text;
      line-height: 25px;
      font-size: 12px;
    }
    .bar-value {
      font-size: 20px;
      font-family: MilliardBold;
    }
    .bar-text {
      font-size: 11px;
    }
  }
}
