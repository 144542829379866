.success-modal {
  background-color: $primary;
  min-height: 400px;
  background-image: url('../assets/images/bg-image.png');
  background-position: top left;
  background-repeat: no-repeat;
  overflow: hidden;
  padding: 60px;
  border-radius: 24px;
  position: relative;
  .ant-modal-content {
    background-color: transparent;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .success-modal-content {
    width: 60%;
    .logo {
      width: 100%;
    }
    .modal-title {
      color: $white;
      font-size: 32px;
    }
  }
}
