.pt5 {
  padding-top: 5px;
}
.pt10 {
  padding-top: 10px;
}
.pt16 {
  padding-top: 16px;
}
.pt20 {
  padding-top: 20px;
}
.pt30 {
  padding-top: 30px;
}
.pb5 {
  padding-bottom: 5px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb16 {
  padding-bottom: 16px;
}
.pb20 {
  padding-bottom: 20px !important;
}
.pb50 {
  padding-bottom: 50px !important;
}
.p16 {
  padding: 16px;
}
.p32 {
  padding: 32px;
}
.pr10 {
  padding-right: 10px;
}
.pr20 {
  padding-right: 20px;
}
.pl10 {
  padding-left: 10px;
}
.pl20 {
  padding-left: 20px;
}
.pl40 {
  padding-left: 40px;
}
.ph10 {
  padding-right: 10px;
  padding-left: 10px;
}
.p10 {
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
}
.ph20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}
.ph40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}
.pv0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.pv10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.pv20 {
  padding: 20px 0;
}
