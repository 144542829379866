/**
 * Carriers modal list-table styling
 */
.carriers-list-modal-content {
  .table-footer {
    li {
      padding: inherit;
      margin-bottom: auto;
      font-weight: unset;
      font-size: initial;
    }
  }
}
