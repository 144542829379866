.header {
  height: $headerHeight;
  width: calc(100vw - #{$sideMenuWidth});
  position: fixed;
  top: 0;
  z-index: 500;
  border-bottom: 1px solid $backgroundGray;
  left: $sideMenuWidth;
  .interface-wrapper {
    .active-menu-item {
      padding: 0 24px;
      border-right: 1px solid $borderGray;
    }
  }
  .user-info-wrapper {
    display: flex;
    align-items: center;
    padding: 0 24px;
    .notification-info-wrapper {
      position: relative;
      .notification-dot {
        position: absolute;
        top: 0;
        right: -2px;
      }

      &:hover {
        cursor: pointer;
      }
    }
    .user-info {
      .user-photo-wrapper {
        width: 45px;
      }
    }
    .user-photo {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      &.table-thumb {
        height: 25px;
        width: 25px;
        border-radius: 25px;
      }
      &.detail-thumb {
        height: 40px;
        width: 40px;
        border-radius: 40px;
      }
      &.detail-photo {
        height: 200px;
        width: 200px;
        border-radius: 200px;
      }
    }
  }

  .notification {
    &-list {
      overflow-y: auto;

      height: 300px;
      width: 300px;
      z-index: 100;
    
      &::-webkit-scrollbar {
        height: 10px;
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: $textGray;
        border-radius: 100px;
        height: 50px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: $backgroundGray;
      }
    }

    &-list-item {
      padding: 10px 0px;
      min-height: 40px;

      &__header {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;

        margin-right: 10px;
      }
    }
  }
}
